import React, { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import Select, { MultiValue } from "react-select";
import AddCircle from "../../../../assets/images/add-circle.svg";
import Trash from "../../../../assets/images/trash.svg";
import {
	Approver,
	createCustomApprovaer,
	CreateCustomApprover,
	Level,
} from "../../../../services/approval/approval";
import { fetchProjectApproversAsync, fetchCostomReviewersAsync } from "../../../../slices/approve/approveSlice";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { RootState } from "../../../../store/store";
import { toast } from "react-toastify";

interface SetupApprovalProps {
	closeModal: () => void;
	show: boolean;
	approver: Approver[];
	customapprover: Approver[];
	onCreateSuccess: () => void;
}

interface ReviewerLevel {
	level: number;
	user_ids: number[];
	approval_type: "Approval" | "Review";
}

interface FormValues {
	selectedProject: string;
	reviewerLevels: ReviewerLevel[];
	finalApprover: {
		user_ids: number[];
		approval_type: "Approval" | "Review";
	};
}

const SetupApprovalLevel: React.FC<SetupApprovalProps> = ({
	closeModal,
	show,
	approver,
	customapprover,
	onCreateSuccess,
}) => {
	const dispatch = useAppDispatch();
	const projectapprover = useAppSelector(
		(state: RootState) => state.approver.projectapprovers
	);
	const projects = useAppSelector((state) => state.project.projects?.data) || [];
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || 0
	);
	const projectId = projects.length > 0 ? projects[0].id : '';

	const [formValues, setFormValues] = useState<FormValues>({
		selectedProject: "",
		reviewerLevels: [
			{ level: 1, user_ids: [], approval_type: "Review" },
		],
		finalApprover: { user_ids: [], approval_type: "Approval" },
	});
	const [errors, setErrors] = useState<{
		selectedProject?: string;
	}>({});

	const handleProjectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const value = e.target.value;
		setFormValues(prev => ({
			...prev,
			selectedProject: value
		}));
		if (!value) {
			setErrors(prev => ({ ...prev, selectedProject: 'Project selection is required' }));
		} else {
			setErrors(prev => {
				const newErrors = { ...prev };
				delete newErrors.selectedProject;
				return newErrors;
			});
		}
	};

	const handleReviewerChange = (index: number, selectedOptions: MultiValue<{ value: number; label: string }>) => {
		setFormValues(prev => {
			const newReviewerLevels = [...prev.reviewerLevels];
			newReviewerLevels[index] = {
				...newReviewerLevels[index],
				user_ids: selectedOptions.map(option => option.value)
			};
			return { ...prev, reviewerLevels: newReviewerLevels };
		});
	};

	const handleFinalApproverChange = (selectedOptions: MultiValue<{ value: number; label: string }>) => {
		setFormValues(prev => ({
			...prev,
			finalApprover: {
				...prev.finalApprover,
				user_ids: selectedOptions.map(option => option.value)
			}
		}));
	};

	React.useEffect(() => {
		if (companyId && formValues.selectedProject) {
			dispatch(
				fetchCostomReviewersAsync({
					companyId: Number(companyId),
					projectId: formValues.selectedProject,
				})
			);
			dispatch(
				fetchProjectApproversAsync({
					companyId: Number(companyId),
					projectId: formValues.selectedProject,
				})
			);
		}
	}, [companyId, dispatch, formValues.selectedProject]);


	const addLevel = () => {
		setFormValues(prev => ({
			...prev,
			reviewerLevels: [
				...prev.reviewerLevels,
				{
					level: prev.reviewerLevels.length + 1,
					user_ids: [],
					approval_type: "Review"
				}
			]
		}));
	};

	const removeLevel = (levelToRemove: number) => {
		setFormValues(prev => ({
			...prev,
			reviewerLevels: prev.reviewerLevels
				.filter(level => level.level !== levelToRemove)
				.map((level, i) => ({ ...level, level: i + 1 }))
		}));
	};


	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!formValues.selectedProject) {
			setErrors({ selectedProject: 'Project selection is required' });
			return;
		}

		const updatedFinalApprover: Level = {
			level: formValues.reviewerLevels.length + 1,
			user_ids: formValues.finalApprover.user_ids,
			approval_type: formValues.finalApprover.approval_type,
		};

		const updatedLevels = [...formValues.reviewerLevels, updatedFinalApprover];

		const requestBody: CreateCustomApprover = {
			levels: updatedLevels,
			projectId: formValues.selectedProject || projectId,
			company_id: companyId,
		};

		try {
			const response = await createCustomApprovaer(requestBody);
			console.log("API Response:", response);
			toast.success("Custom Approval level created successfully");
			onCreateSuccess();
			closeModal();
		} catch (error: any) {
			console.error("Error creating approver:", error);
			let errorMessage = "Failed to create approver.";
			if (error.response?.data?.message) {
				errorMessage = error.response.data.message;
			} else if (error.message) {
				errorMessage = error.message;
			}
			toast.error(errorMessage);
		}
	};

	return (
		<Modal
			centered
			size="lg"
			show={show}
			onHide={closeModal}
			style={{ maxHeight: "100vh", overflowY: "auto" }}
		>
			<form onSubmit={handleSubmit}>
				<Modal.Header closeButton>
					<h4 className="confirm-header">Setup approval level</h4>
				</Modal.Header>
				<section className="mt-2 px-4 py-2">
					<Row className="w-100">
						<label htmlFor="selectedProject" className="mb-1">
							Select project
						</label>
						<select
							name="selectedProject"
							className={`fluent-input w-95 ${errors.selectedProject ? 'is-invalid' : ''}`}
							onChange={handleProjectChange}
							value={formValues.selectedProject}
						>
							<option value="">Select project</option>
							{projects.map((project) => (
								<option key={project.id} value={project.id}>
									{project.project_name}
								</option>
							))}
						</select>
						{errors.selectedProject && (
							<div className="invalid-feedback">{errors.selectedProject}</div>
						)}
					</Row>
				</section>

				<section className="mt-2 px-4 py-2">
					<h4>Levels</h4>
					<div className="overflow-scroll" style={{ maxHeight: "300px" }}>
						{formValues.reviewerLevels.map((reviewerLevel, index) => (
							<Row className="w-100 mb-3" key={reviewerLevel.level}>
								<label
									htmlFor={`reviewerLevels.${index}.user_ids`}
									className="mb-1 w-100"
								>
									Level {reviewerLevel.level}
								</label>
								<div className="w-90 d-inline-flex justify-content-between align-items-center">
									<Select
										className="fluent-input w-100 border-0"
										value={customapprover
											.filter(reviewer => reviewerLevel.user_ids.includes(reviewer.id))
											.map(reviewer => ({
												value: reviewer.id,
												label: `${reviewer.first_name} ${reviewer.last_name}`
											}))}
										onChange={(selectedOptions) => handleReviewerChange(index, selectedOptions)}
										options={customapprover
											.filter(reviewer => !formValues.reviewerLevels
												.flatMap(level => level.user_ids)
												.filter((_, i) => i !== index)
												.includes(reviewer.id))
											.map(reviewer => ({
												value: reviewer.id,
												label: `${reviewer.first_name} ${reviewer.last_name}`
											}))}
										placeholder="Select Reviewers"
										isMulti
									/>
									{reviewerLevel.level > 1 && (
										<Button
											className="bg-transparent border-0"
											onClick={() => removeLevel(reviewerLevel.level)}
											type="button"
										>
											<img src={Trash} alt="trash" />
										</Button>
									)}
								</div>
							</Row>
						))}
						<Row className="w-100">
							<label htmlFor="finalApprover.user_ids" className="mb-1 w-100">
								Final Approver
							</label>
							<div className="w-90">
								<Select
									className="fluent-input w-100 border-0"
									value={projectapprover
										.filter(approver => formValues.finalApprover.user_ids.includes(approver.id))
										.map(approver => ({
											value: approver.id,
											label: `${approver.first_name} ${approver.last_name}`
										}))}
									onChange={handleFinalApproverChange}
									options={projectapprover
										.filter(approver => !formValues.reviewerLevels
											.flatMap(level => level.user_ids)
											.includes(approver.id))
										.map(approver => ({
											value: approver.id,
											label: `${approver.first_name} ${approver.last_name}`
										}))}
									placeholder="Select Final Approver"
									isMulti
								/>
							</div>
						</Row>
						{formValues.reviewerLevels.length < 4 && (
							<Row className="w-10">
								<Button
									className="d-inline-flex justify-content-between align-items-center bg-transparent border-0 text-color-1"
									onClick={addLevel}
									type="button"
								>
									<img src={AddCircle} alt="add circle" />
									<span>Add</span>
								</Button>
							</Row>
						)}
					</div>
				</section>

				<Modal.Footer>
					<Row>
						<Col md={6}>
							<div className="mt-1">
								<Button onClick={closeModal} className="cancel-btn" type="button">
									<span className="text-center">Cancel</span>
								</Button>
							</div>
						</Col>
						<Col md={6}>
							<div className="mt-1">
								<Button
									type="submit"
									className="border-0 create-btn bg-color-1"
								>
									<span className="text-center">Create setup</span>
								</Button>
							</div>
						</Col>
					</Row>
				</Modal.Footer>
			</form>
		</Modal>
	);
};

export default SetupApprovalLevel;
