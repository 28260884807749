import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    Budget, createBudget, CreateBudgetData, CreateBudgetResponse,
    getABudget,
    getAllBudgets, updateBudget, UpdateBudgetData, UpdateBudgetResponse,
} from "../../services/budget/budgetService";

export interface BudgetState {
    createBudgetResponse?: CreateBudgetResponse | null;
    updateBudgetResponse?: UpdateBudgetResponse | null;
    budgets: Budget[];
    budgetData: Budget | null;
    budgetId?: string;

}

const initialState: BudgetState = {
    createBudgetResponse: null,
    updateBudgetResponse: null,
    budgets: [],
    budgetData: null,
    budgetId: '',
}

export const createBudgetAsync = createAsyncThunk(
    'budgets/createBudget',
    async (createBudgetData: CreateBudgetData, { dispatch, rejectWithValue }) => {
        try {
            const response = await createBudget(createBudgetData);
            if (response.data && response.data.id) {
                const budgetId = response.data.id;
                localStorage.setItem("budgetId", budgetId);
                dispatch(setBudgetId(budgetId));
            }
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
)

export const updateBudgetAsync = createAsyncThunk(
    "requests/updateBudget",
    async ({
        updateBudgetData,
        id,
    }: {
        updateBudgetData: UpdateBudgetData;
        id: string;
    }) => {
        const response = await updateBudget(updateBudgetData, id);
        console.log("API response:", response);
        return response;
    },
);

export const getAllBudgetsAsync = createAsyncThunk(
    'budgets/getAllBudgets',
    async ({ companyId, projectId }: { companyId: string; projectId: string }) => {
        try {
            const budgets = await getAllBudgets(companyId, projectId);
            return budgets;
        } catch (error) {
            console.error('Error fetching budgets:', error);
            throw error;
        }
    }
)
export const getABudgetAsync = createAsyncThunk(
    "requests/getABudget",
    async ({
        companyId,
        id,
        projectId,
    }: {
        companyId: string;
        id: string;
        projectId: string;
    }) => {
        try {
            console.log("Company ID:", companyId);
            const budget = await getABudget(companyId, id, projectId);
            return budget;
        } catch (error) {
            console.error("Error fetching budget:", error);
            throw error;
        }
    },
);
const budgetSlice = createSlice({
    name: "budget",
    initialState,
    reducers: {
        setBudgetId: (state, action) => {
            state.budgetId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createBudgetAsync.fulfilled, (state, action) => {
            state.createBudgetResponse = action.payload;
        })
        builder.addCase(createBudgetAsync.rejected, (state) => {
            state.createBudgetResponse = null;
        })
        builder.addCase(createBudgetAsync.pending, (state) => {
            state.createBudgetResponse = null;
        })
        builder.addCase(updateBudgetAsync.fulfilled, (state, action) => {
            state.updateBudgetResponse = action.payload;
        })
        builder.addCase(updateBudgetAsync.rejected, (state) => {
            state.updateBudgetResponse = null;
        })

        builder.addCase(updateBudgetAsync.pending, (state) => {
            state.updateBudgetResponse = null;
        })

        builder.addCase(getAllBudgetsAsync.pending, (state, action) => {
            state.budgets = [];
        })
        builder.addCase(getAllBudgetsAsync.fulfilled, (state, action) => {
            state.budgets = action.payload;
        })
        builder.addCase(getAllBudgetsAsync.rejected, (state, action) => {
            state.budgets = [];
        })
        builder.addCase(getABudgetAsync.fulfilled, (state, action) => {
            state.budgetData = action.payload;
        });
        builder.addCase(getABudgetAsync.pending, (state) => {
            state.budgetData = null;
        })
        builder.addCase(getABudgetAsync.rejected, (state) => {
            state.budgetData = null;
        })
    }
})
export const { setBudgetId } = budgetSlice.actions;
export default budgetSlice.reducer;