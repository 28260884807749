import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	Task,
	TaskData,
	CreateTaskResponse,
	UpdateTaskResponse,
	GetAllTasksResponse,
	GetATaskResponse,
	DeleteTaskResponse,
	createTask,
	updateTask,
	getAllTasks,
	getAllDropdownTasks,
	getATask,
	deleteTask,
	IssuesData,
	CreateIssueResponse,
	GetAllIssuesResponse,
	GetAnIssueResponse,
	createIssue,
	getAllIssues,
	getAnIssue,
	searchTasks,
	searchIssues,
	updateIssue,
	Issue,
} from "../../services/project-management/project-management-service";

export interface ProjectManagementState {
	createTaskResponse: CreateTaskResponse | null;
	updateTaskResponse: UpdateTaskResponse | null;
	getAllTasksResponse: GetAllTasksResponse | null;
	getATaskResponse: GetATaskResponse | null;
	deleteTaskResponse: DeleteTaskResponse | null;
	tasks?: GetAllTasksResponse | null;
	taskData?: TaskData | null;
	task_id?: string;
	createIssueResponse: CreateIssueResponse | null;
	getAllIssuesResponse: GetAllIssuesResponse | null;
	getAnIssueResponse: GetAnIssueResponse | null;
	issues?: GetAllIssuesResponse | null;
	issuesData?: IssuesData | null;
	issue_id?: string;
	meta?: any;
	error?: string | null;
}

const initialState: ProjectManagementState = {
	createTaskResponse: null,
	updateTaskResponse: null,
	getAllTasksResponse: null,
	getATaskResponse: null,
	deleteTaskResponse: null,
	tasks: null,
	taskData: null,
	task_id: "",
	createIssueResponse: null,
	getAllIssuesResponse: null,
	getAnIssueResponse: null,
	issues: null,
	issuesData: null,
	issue_id: "",
	error: null,
	meta: null,
};

export const createTaskAsync = createAsyncThunk(
	"projectManagement/createTask",
	async (data: TaskData, { dispatch, rejectWithValue }) => {
		const response = await createTask(data);
		return response;
	},
);

export const updateTaskAsync = createAsyncThunk(
	"projectManagement/updateTask",
	async (data: Task) => {
		const response = await updateTask(data);
		return response;
	},
);

export const getAllTasksAsync = createAsyncThunk(
	"projectManagement/getAllTasks",
	async ({
		company_id,
		projectId,
		page,
	}: {
		company_id: string;
		projectId: string;
		page: number;
	}) => {
		const response = await getAllTasks(company_id, projectId, page);
		return response;
	},
);
export const searchTasksAsync = createAsyncThunk(
	"projectManagement/searchTasks",
	async (
		{
			company_id,
			projectId,
			searchTerm,
			page,
		}: {
			company_id: string;
			projectId: string;
			searchTerm: string;
			page: number;
		},
		{ rejectWithValue },
	) => {
		try {
			const response = await searchTasks(
				company_id,
				projectId,
				searchTerm,
				page,
			);
			return response;
		} catch (err) {
			return rejectWithValue("Search tasks failed.");
		}
	},
);
export const searchIssuesAsync = createAsyncThunk(
	"projectManagement/searchIssues",
	async (
		{
			company_id,
			projectId,
			searchTerm,
			page,
		}: {
			company_id: string;
			projectId: string;
			searchTerm: string;
			page: number;
		},
		{ rejectWithValue },
	) => {
		try {
			const response = await searchIssues(
				company_id,
				projectId,
				searchTerm,
				page,
			);
			return response;
		} catch (err) {
			return rejectWithValue("Search issues failed.");
		}
	},
);
export const getAllDropdownTasksAsync = createAsyncThunk(
	"projectManagement/getAllTasks",
	async ({
		companyId,
		projectId,
	}: {
		companyId: string;
		projectId: string;
	}) => {
		const response = await getAllDropdownTasks(companyId, projectId);
		return response;
	},
);

export const getATaskAsync = createAsyncThunk(
	"projectManagement/getATask",
	async ({
		id,
		company_id,
		projectId,
	}: {
		id: string;
		company_id: string;
		projectId: string;
	}) => {
		const response = await getATask(id, company_id, projectId);
		return response.data;
	},
);

export const deleteTaskAsync = createAsyncThunk(
	"projectManagement/deleteTask",
	async ({
		id,
		projectId,
		company_id,
	}: {
		id: string;
		projectId: string;
		company_id: string;
	}) => {
		const response = await deleteTask(id, projectId, company_id);
		return response;
	},
);
export const createIssueAsync = createAsyncThunk(
	"projectManagement/createIssue",
	async (formData: IssuesData, { rejectWithValue, dispatch }) => {
		try {
			const response = await createIssue(formData);
			if (response.data && response.data.id) {
				const IssuesId = response.data.id;
				localStorage.setItem("IssuesId", IssuesId);
				dispatch(setIssueId(IssuesId));
			}
			return response;
		} catch (error: any) {
			return rejectWithValue(
				error.response?.data || { message: "Failed to create issue" },
			);
		}
	},
);
export const updateIssueAsync = createAsyncThunk(
	"projectManagement/updateIssue",
	async (data: Issue) => {
		const response = await updateIssue(data);
		return response;
	},
);
export const getAllIssuesAsync = createAsyncThunk(
	"projectManagement/getAllIssues",
	async ({
		company_id,
		projectId,
		page,
	}: {
		company_id: string;
		projectId: string;
		page: number;
	}) => {
		const response = await getAllIssues(company_id, projectId, page);
		return response;
	},
);

export const getAnIssueAsync = createAsyncThunk(
	"projectManagement/getAnIssue",
	async ({
		id,
		company_id,
		projectId,
	}: {
		id: string;
		company_id: string;
		projectId: string;
	}) => {
		const response = await getAnIssue(id, company_id, projectId);
		return response.data;
	},
);

export const projectManagementSlice = createSlice({
	name: "projectManagement",
	initialState,
	reducers: {
		setTaskId: (state, action) => {
			state.task_id = action.payload;
		},
		setTasks: (state, action) => {
			state.tasks = action.payload;
		},
		setIssueId: (state, action) => {
			state.issue_id = action.payload;
		},
		setIssues: (state, action) => {
			state.issues = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(createTaskAsync.fulfilled, (state, action) => {
				state.createTaskResponse = action.payload;
			})
			.addCase(createTaskAsync.rejected, (state, action) => {
				state.createTaskResponse = null;
				state.error = action.error.message || "Failed to create task";
			})
			.addCase(updateTaskAsync.fulfilled, (state, action) => {
				state.updateTaskResponse = action.payload;
			})
			.addCase(updateTaskAsync.rejected, (state, action) => {
				state.updateTaskResponse = null;
				state.error = action.error.message || "Failed to update task";
			})
			.addCase(getAllTasksAsync.fulfilled, (state, action) => {
				state.tasks = action.payload;
				state.meta = action.payload.meta;
			})
			.addCase(getAllTasksAsync.rejected, (state, action) => {
				state.getAllTasksResponse = null;
				state.tasks = null;
				state.error = action.error.message || "Failed to fetch tasks";
			})
			.addCase(searchTasksAsync.fulfilled, (state, action) => {
				state.tasks = action.payload;
				state.meta = action.payload.meta;
			})
			.addCase(searchTasksAsync.rejected, (state, action) => {
				state.getAllTasksResponse = null;
				state.tasks = null;
				state.error = action.error.message || "Failed to fetch tasks";
			})
			.addCase(getATaskAsync.fulfilled, (state, action) => {
				state.taskData = action.payload;
			})
			.addCase(getATaskAsync.rejected, (state, action) => {
				state.getATaskResponse = null;
				state.taskData = null;
				state.error = action.error.message || "Failed to fetch task";
			})
			.addCase(deleteTaskAsync.fulfilled, (state, action) => {
				state.deleteTaskResponse = action.payload;
			})
			.addCase(deleteTaskAsync.rejected, (state, action) => {
				state.deleteTaskResponse = null;
				state.error = action.error.message || "Failed to delete task";
			})
			.addCase(createIssueAsync.fulfilled, (state, action) => {
				state.createIssueResponse = action.payload;
			})
			.addCase(createIssueAsync.rejected, (state, action) => {
				state.createIssueResponse = null;
				state.error = action.error.message || "Failed to create issue";
			})
			.addCase(getAllIssuesAsync.fulfilled, (state, action) => {
				state.issues = action.payload;
				state.meta = action.payload.meta;
			})
			.addCase(getAllIssuesAsync.rejected, (state, action) => {
				state.getAllIssuesResponse = null;
				state.issues = null;
				state.error = action.error.message || "Failed to fetch issues";
			})
			.addCase(searchIssuesAsync.fulfilled, (state, action) => {
				state.issues = action.payload;
				state.meta = action.payload.meta;
			})
			.addCase(searchIssuesAsync.rejected, (state, action) => {
				state.getAllIssuesResponse = null;
				state.issues = null;
				state.error = action.error.message || "Failed to fetch issues";
			})
			.addCase(getAnIssueAsync.fulfilled, (state, action) => {
				state.issuesData = action.payload;
			})
			.addCase(getAnIssueAsync.rejected, (state, action) => {
				state.getAnIssueResponse = null;
				state.issuesData = null;
				state.error = action.error.message || "Failed to fetch issue";
			});
	},
});

export const { setTaskId, setTasks, setIssueId, setIssues } =
	projectManagementSlice.actions;

export default projectManagementSlice.reducer;
