import React, { useEffect, useState } from 'react';
import { Container, Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { updateBudgetAsync, getAllBudgetsAsync, getABudgetAsync, setBudgetId } from '../../../../slices/budget/budgetSlice';
import { RootState } from '../../../../store/store';
import { toast } from "react-toastify";
import CurrencyInput from '../../../../components/form/currency-input';

interface UpdateMaterialBudgetValues {
    budget_amount: string;
    id: string;
    company_id: string;
}
interface UpdateMaterialBudgetProps {
    closeModal: () => void;
    show: boolean;
    onEditSuccess: () => void;
    projectId: string;
    budgetId: string;
}
const UpdateMaterialBudget: React.FC<UpdateMaterialBudgetProps> = ({ show, closeModal, onEditSuccess, projectId, budgetId }) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);

    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const budgetData = useAppSelector((state: any) => state.budget.budgetData);

    useEffect(() => {
        if (budgetId) {
            dispatch(setBudgetId(budgetId));
            console.log("Fetching budget with ID:", budgetId);
            dispatch(getABudgetAsync({ companyId, id: budgetId, projectId }))
                .unwrap()
                .then(() => setLoading(false))
                .catch(() => setLoading(false)); // Ensure loading is false even if the request fails
        } else {
            console.log("budgetId is not set");
            setLoading(false);
        }
    }, [dispatch, companyId, budgetId, projectId]);

    return (
        <Container fluid className=''>
            <Modal size="lg" show={show} onHide={closeModal} centered >
                <div className='px-5 py-3'>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            budget_amount: budgetData?.budget_amount || "",
                            id: budgetId,
                            company_id: companyId || "",
                            projectId: projectId || '',

                        }}
                        validationSchema={Yup.object().shape({
                            budget_amount: Yup.string().required('Required'),
                            id: Yup.string(),
                            company_id: Yup.string().required('Required'),
                            projectId: Yup.string().required('Required'),
                        })}
                        onSubmit={(values: UpdateMaterialBudgetValues, { setSubmitting }) => {
                            console.log(values);
                            const plainBudgetAmount = parseFloat(values.budget_amount.replace(/[^0-9.]/g, ''));
                            const roundedBudgetAmount = Math.round(plainBudgetAmount);
                            dispatch(
                                updateBudgetAsync({
                                    updateBudgetData: {
                                        budget_amount: roundedBudgetAmount.toString(),
                                        id: values.id, // Ensure this is included
                                        company_id: companyId,
                                        projectId: projectId
                                    },
                                    id: values.id, // Also provide it separately if needed
                                })
                            ).unwrap()
                                .then((response) => {
                                    toast.success("Material budget updated successfully");
                                    dispatch(getAllBudgetsAsync({ companyId, projectId }));
                                    onEditSuccess();
                                    closeModal();
                                })
                                .catch((error) => {
                                    const errorMessage = Object.values(error.message).flat().join(" ");
                                    toast.error(errorMessage);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}                >
                        {({ handleSubmit, handleChange, handleBlur, touched, errors, values }) => (
                            <Form onSubmit={handleSubmit}>
                                <Modal.Header className='text-start mt-3' closeButton>
                                    <h5>Material</h5>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col className='mt-2'>
                                            <label className='mt-2'>
                                                <p>Estimated cost for material{" "}
                                                    <span className="text-danger">*</span>
                                                </p>
                                            </label>
                                            <CurrencyInput
                                                name="budget_amount"
                                                placeholder="Enter amount"
                                                className={`fluent-input ${touched.budget_amount && errors.budget_amount ? 'is-invalid' : ''}`}
                                                value={values.budget_amount}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Col>
                                    </Row>
                                    <Modal.Footer className='mt-5'>
                                        <Row >
                                            <Col md={6}>
                                                <div className=' mt-1'>
                                                    <Button onClick={closeModal} className='cancel-btn'>
                                                        <span className="text-center">Cancel </span>                                        </Button>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className=' mt-1'>
                                                    <Button type='submit' className=' border-0 create-btn bg-color-1'>
                                                        <span className="text-center">Edit </span>                                        </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Modal.Footer>
                                </Modal.Body>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </Container>
    );
};

export default UpdateMaterialBudget;
