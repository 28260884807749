import React, { useEffect } from "react";
import { Form, Button, Modal, Container, Col, Row, Spinner } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
    createApprovalLevelAsync,
    fetchCompanyApproversAsync,
} from "../../../../slices/approve/approveSlice";
import { RootState } from "../../../../store/store";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { InputLabel, Select, MenuItem, FormControl } from "@mui/material";


interface DefaultApproverProps {
    closeModal: () => void;
    show: boolean;
    onCreateSuccess: () => void;
}

const DefaultApprover: React.FC<DefaultApproverProps> = ({ closeModal, show, onCreateSuccess }) => {
    const dispatch = useAppDispatch();

    const approvers = useAppSelector(
        (state: RootState) => state.approver.approvers,
    );
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || 0,
    );
    useEffect(() => {
        if (companyId) {
            dispatch(fetchCompanyApproversAsync(companyId));
        }
    }, [companyId, dispatch]);
    console.log("Approvers:", approvers);
    const createApproval = async (values: { user_id: string }) => {
        console.log("Submitting with values:", values);
        if (!values.user_id || !companyId) {
            toast.error("Please select approver.");
            return;
        }
        const data = {
            id: companyId,
            company_id: companyId,
            user_id: values.user_id,
        };
        try {
            const response = await dispatch(createApprovalLevelAsync(data)).unwrap();
            console.log("Response:", response);
            toast.success("Approval level updated successfully");
            onCreateSuccess();
            closeModal();
        } catch (error: any) {
            console.error("Error creating approver:", error);
            const errorMessage = error.message || "Failed to create approver.";
            toast.error(errorMessage);
        }
    };
    return (
        <Container>
            <Modal
                centered
                show={show}
                onHide={closeModal}
            >
                <div className='px-4 '>
                    <Formik
                        initialValues={{
                            company_id: companyId || "",
                            id: companyId || '',
                            user_id: '',
                        }}
                        onSubmit={createApproval}>
                        {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
                            <Form onSubmit={handleSubmit}>
                                <Modal.Header closeButton className='text-start'>
                                    <h4 className="confirm-header">Change approver</h4>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <label className="mt-2">
                                            <p>
                                                Choose your preferred approver
                                            </p>
                                        </label>
                                    </Row>
                                    <Row>
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel>Select approver</InputLabel>
                                            <Select
                                                label="Select approver"
                                                name="user_id"
                                                value={values.user_id}
                                                onChange={(event) => {
                                                    setFieldValue("user_id", event.target.value);
                                                    console.log("User ID selected:", event.target.value);  // Add this line
                                                }}                                          >
                                                {approvers?.map((approver) => (
                                                    <MenuItem key={approver.id} value={approver.id}>
                                                        {approver.first_name} {approver.last_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer className='mt-2'>
                                    <Row >
                                        <Col md={6}>
                                            <div className=' mt-1'>
                                                <Button onClick={closeModal} className='cancel-btn'>
                                                    <span className="text-center">Cancel </span>                                        </Button>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className=' mt-1'>
                                                <Button type='submit' className=' border-0 create-btn bg-color-1'
                                                    disabled={isSubmitting || !values.user_id}                                                >
                                                    {isSubmitting ? (
                                                        <Spinner animation="border" size="sm" />
                                                    ) : (
                                                        <span className="text-center"> Save changes</span>
                                                    )}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </Container>
    )
}

export default DefaultApprover