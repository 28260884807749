import Layout from "components/layouts/layout";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import searchIcon from "../../../assets/images/search-icon.svg";
import action from "../../../assets/images/action-vector.png";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Nav, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import {
	deleteTaskAsync,
	getAllIssuesAsync,
	getAllTasksAsync,
	searchIssuesAsync,
	searchTasksAsync,
} from "slices/project-management/project-management-slice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import { extractPermissionNames } from "util/transformPermissions";
import { hasPermission } from "util/permissionchecker";
import CreateTask from "./modals/create-schedule";
import UpdateTask from "./modals/edit-schedule";

import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import gantt from "dhtmlx-gantt";
import { Pagination } from "@mui/material";
import NoIssue from "./no-issue";
import CreateIssue from "./modals/create-issue";
import UpdateIssue from "./modals/edit-issue";
import ViewImage from "./modals/view-image";

// Gantt-chart interfaces
interface ScaleConfig {
	scale_unit: "day" | "week" | "month";
	date_scale: string;
	subscales?: Array<{
		unit: "day" | "week" | "month";
		step: number;
		date: Date | null;
	}>;
}

interface GanttTask {
	id: string;
	text: string;
	start_date: string;
	duration: number;
	color: string;
}

interface GanttChartData {
	data: GanttTask[]; // data type for gantt chart  data
}

const dayScaleConfig: ScaleConfig = {
	scale_unit: "day",
	date_scale: "%d %M %Y",
};

const weekScaleConfig: ScaleConfig = {
	scale_unit: "week",
	date_scale: "Wk %W %Y",
};

const monthScaleConfig: ScaleConfig = {
	scale_unit: "month",
	date_scale: "%M %Y",
};
// Gantt-chart interfaces end

type RequestCache = {
	[key: string]: any;
};

function ProjectManagement() {
	const dispatch = useAppDispatch();
	const userData = useAppSelector((state: any) => state.user?.userData?.user);
	const isAdmin = userData?.system_role === "admin";
	const permissions = isAdmin
		? null
		: extractPermissionNames(userData?.permissions?.permissions || []);
	const tasks = useAppSelector(
		(state: RootState) => state.projectManagement.tasks?.data,
	);
	const tasksMeta = useAppSelector(
		(state: RootState) => state.projectManagement.tasks?.meta,
	);
	const issues = useAppSelector(
		(state: RootState) => state.projectManagement.issues?.data,
	);
	const issuesMeta = useAppSelector(
		(state: RootState) => state.projectManagement.issues?.meta,
	);

	const companyId = localStorage.getItem("userCompanyID");

	const { projectId } = useParams<{ projectId: string }>();

	const [activeTab, setActiveTab] = useState<
		"tasks" | "gantt-chart" | "issues"
	>("tasks");

	const [editTaskOpen, setEditTaskOpen] = useState<boolean>(false);
	const [editIssueOpen, setEditIssueOpen] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [createRequestType, setCreateRequestType] = useState<
		"task" | "issue" | null
	>(null);
	const [openModal, setOpenModal] = useState<{
		createTask: string | null;
		createIssue: string | null;
	}>({
		createTask: null,
		createIssue: null,
	});
	const handleOpenCreateModal = (type: "task" | "issue") => {
		setCreateRequestType(type);
		setOpenModal((prevState) => ({
			...prevState,
			[`create${type.charAt(0).toUpperCase() + type.slice(1)}`]: type,
		}));
	};

	const handleCloseCreateModal = () => {
		setOpenModal((prevState) => ({
			...prevState,
			createTask: null,
			createIssue: null,
		}));
	};

	const [searchTasksQuery, setSearchTasksQuery] = useState<string>("");
	const [searchIssuesQuery, setSearchIssuesQuery] = useState<string>("");

	const [selectedTaskId, setSelectedTaskId] = useState<string>("");
	const [selectedIssueId, setSelectedIssueId] = useState<string | null>(null);

	const [selectedIssueName, setSelectedIssueName] = useState<string | null>(null);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [pageNumber, setPage] = useState(1);
	const [tasksPage, setTasksPage] = useState(1);
	const [issuesPage, setIssuesPage] = useState(1);

	const [taskDropdownOpen, setTaskDropdownOpen] = useState<{
		[key: number]: boolean;
	}>({});
	const [issueDropdownOpen, setIssueDropdownOpen] = useState<{
		[key: number]: boolean;
	}>({});


	const [pageNumbers, setPageNumbers] = useState({
		"tasks": 1,
		"gantt-chart": 1,
		"issues": 1,
	});
	useEffect(() => {
		if (activeTab === "tasks") {
			setTasksPage(1);
		} else if (activeTab === "gantt-chart") {
			setTasksPage(1);
		} else if (activeTab === "issues") {
			setIssuesPage(1);
		}
	}, [activeTab]);
	useEffect(() => {
		setPageNumbers((prevState) => ({
			...prevState,
			[activeTab]: 1,
		}));
	}, [activeTab]);

	const [taskCache] = useState<RequestCache>({});
	const [issueCache] = useState<RequestCache>({});
	const fetchRequests = () => {
		if (companyId && projectId) {
			const currentPage = pageNumbers[activeTab];

			if (activeTab === "tasks" && !taskCache[projectId] && searchTasksQuery === "") {
				dispatch(
					getAllTasksAsync({
						company_id: companyId,
						projectId,
						page: currentPage,
					}),
				);
			} else if (activeTab === "gantt-chart" && !taskCache[projectId] && searchTasksQuery === "") {
				dispatch(
					getAllTasksAsync({ company_id: companyId, projectId, page: currentPage }),
				);
			} else if (activeTab === "issues" && !issueCache[projectId] && searchIssuesQuery === "") {
				dispatch(
					getAllIssuesAsync({ company_id: companyId, projectId, page: currentPage }),
				);
			}
		}
	};
	useEffect(() => {
		fetchRequests();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		companyId,
		projectId,
		activeTab,
		taskCache,
		issueCache,
		dispatch,
		pageNumbers,
	]);
	const handleTabSelect = (eventKey: string | null) => {
		if (eventKey) {
			setActiveTab(eventKey as "tasks" | "gantt-chart" | "issues");
		}
	};
	const handleCreateSuccess = () => {
		fetchRequests();
	};

	const handleEditSuccess = () => {
		fetchRequests();
	};
	// const handleDeleteSuccess = () => {
	// 	fetchRequests();
	// };

	const handleTasksChange = (
		event: React.ChangeEvent<unknown>,
		newPage: number,
	) => {
		setPageNumbers((prevState) => ({
			...prevState,
			"tasks": newPage,
		}));
		setTasksPage(newPage);
		if (companyId && projectId) {
			if (searchTasksQuery.trim() === "") {
				dispatch(
					getAllTasksAsync({
						company_id: companyId,
						projectId: projectId,
						page: newPage,
					}),
				);
			} else {
				dispatch(
					searchTasksAsync({
						company_id: companyId,
						projectId: projectId,
						searchTerm: searchTasksQuery,
						page: newPage,
					}),
				);
			}
		}
	};
	const handleIssuesChange = (
		event: React.ChangeEvent<unknown>,
		newPage: number,
	) => {
		setPageNumbers((prevState) => ({
			...prevState,
			"issues": newPage,
		}));
		setIssuesPage(newPage);
		if (companyId && projectId) {
			if (searchTasksQuery.trim() === "") {
				dispatch(
					getAllIssuesAsync({
						company_id: companyId,
						projectId: projectId,
						page: newPage,
					}),
				);
			} else {
				dispatch(
					searchIssuesAsync({
						company_id: companyId,
						projectId: projectId,
						searchTerm: searchIssuesQuery,
						page: newPage,
					}),
				);
			}
		}
	};


	const handleOpenEditTask = (taskId: string) => {
		setEditTaskOpen(true);
		setSelectedTaskId(taskId);
	};
	const handleCloseEditTask = () => {
		setSelectedTaskId("");
		setEditTaskOpen(false);
	};

	const handleDeleteTask = (id: string) => {
		if (companyId && projectId) {
			dispatch(
				deleteTaskAsync({
					id: id,
					projectId: projectId,
					company_id: companyId,
				}),
			);
		}
	};

	const toggleTaskDropdown = (index: number) => {
		setTaskDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const handleOpenEditIssue = (issueId: string) => {
		setEditIssueOpen(true);
		setSelectedIssueId(issueId);
	};
	const handleCloseEditIssue = () => {
		setSelectedIssueId("");
		setEditIssueOpen(false);
	};
	const [showImageModal, setShowImageModal] = useState(false);
	const [selectedImage, setSelectedImage] = useState<string | null>(null);
	const handleViewImage = (imageUrl: string, id: string, issueName: string) => {
		console.log("Selected Image URL:", imageUrl);
		console.log("Selected Issue Name:", issueName);
		if (!imageUrl) {
			alert("No image available for this issue!");
			return;
		}
		const fullImageUrl = imageUrl.startsWith("http")
			? imageUrl
			: `https://syteboxx-api.remsys.com.ng${imageUrl}`;

		setSelectedImage(fullImageUrl);
		setSelectedIssueName(issueName);
		setShowImageModal(true);
	};

	const handleDeleteIssue = (id: string) => {
		if (companyId && projectId) {
			dispatch(
				deleteTaskAsync({
					id: id,
					projectId: projectId,
					company_id: companyId,
				}),
			);
		}
	};
	const toggleIssueDropdown = (index: number) => {
		setIssueDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const getStatusColor = (status: any) => {
		switch (status.toLowerCase()) {
			case "done":
				return "green";
			case "to do":
				return "blue";
			default:
				return "goldenrod";
		}
	};

	// Gantt chart
	const formatDateToChartFormat = (dateStr: any) => {
		const date = new Date(dateStr);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}-${month}-${year}`;
	};

	const data: GanttTask[] =
		tasks?.map((task) => {
			const startDate = new Date(task.start_date);
			const endDate = new Date(task.end_date);

			if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
				throw new Error("Invalid date");
			}

			// Calculate duration in days
			const duration = Math.ceil(
				(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
			);

			return {
				id: task.id,
				text: task.task_name,
				start_date: formatDateToChartFormat(task.start_date), // Formatting the start_date to match the gantt chart format
				duration: duration,
				color: task.task_color,
			};
		}) ?? [];

	// Creating the ganttChartData object
	// Will update using useMemo() to fix the warning
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const ganttChartData: GanttChartData = {
		data,
	};

	const ganttContainer = useRef<HTMLDivElement>(null);
	const [viewConfig, setViewConfig] = useState<ScaleConfig>(monthScaleConfig);
	const [ganttWidths, setGanttWidths] = useState({
		gridWidth: 200,
		scaleWidth: 800,
	});

	useEffect(() => {
		const handleResize = () => {
			const totalWidth = window.innerWidth;
			setGanttWidths({
				gridWidth: totalWidth * 0.1, // 10% of total width
				scaleWidth: totalWidth * 0.9, // 90% of total width
			});
		};

		// Set initial sizes and update on window resize
		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (ganttContainer.current) {
			gantt.init(ganttContainer.current);
			gantt.parse(ganttChartData);
		}
		return () => gantt.clearAll();
	}, [ganttChartData]);

	useEffect(() => {
		gantt.config.scale_unit = viewConfig.scale_unit;
		gantt.config.date_scale = viewConfig.date_scale;
		gantt.config.subscales = viewConfig.subscales || [];
		gantt.config.columns = [
			{
				name: "text",
				label: "Task name",
				tree: false,
				width: "*",
			},
		];
		gantt.render();
	}, [viewConfig]);

	useEffect(() => {
		gantt.config.grid_width = ganttWidths.gridWidth;
		gantt.config.scale_width = ganttWidths.scaleWidth; // Not a standard configuration in dhtmlx-gantt
		gantt.render();
	}, [ganttWidths]);

	useEffect(() => {
		gantt.config.columns = [
			{ name: "text", label: "Task name", tree: false, width: "*" },
		];
		gantt.config.buttons_left = [];
		gantt.render();
	}, []);
	// Gantt chart End

	return (
		<div className="bg-color-8">
			<Layout>
				<div className="mt-5 p-5">
					<h3>Project management</h3>
					<>
						<Nav
							activeKey={activeTab}
							onSelect={handleTabSelect}
							className="nav px-2 py-2"
						>
							<Nav.Item>
								<Nav.Link
									className="py-0 px-2 rounded fw-light mx-2"
									eventKey="tasks"
									style={{
										color: activeTab === "tasks" ? "#FFFFFF " : "#747487",
										textDecoration: "none",
										backgroundColor:
											activeTab === "tasks" ? "#0F6CBD " : "transparent",
									}}
								>
									Scheduling
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									className="py-0 px-2 rounded fw-light mx-2"
									eventKey="gantt-chart"
									style={{
										color:
											activeTab === "gantt-chart" ? " #FFFFFF " : " #747487",
										textDecoration: "none",
										backgroundColor:
											activeTab === "gantt-chart" ? "#0F6CBD " : "transparent",
									}}
								>
									Gantt chart
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									className="py-0 px-2 rounded fw-light mx-2"
									eventKey="issues"
									style={{
										color: activeTab === "issues" ? " #FFFFFF " : " #747487",
										textDecoration: "none",
										backgroundColor:
											activeTab === "issues" ? "#0F6CBD " : "transparent",
									}}
								>
									Issues
								</Nav.Link>
							</Nav.Item>
						</Nav>
						<div>
							{activeTab === "tasks" && (
								<div className="bg-color-9 px-5 pt-3 mt-4 w-100">
									<Row className="d-inline-flex justify-content-end align-items-center w-100">
										<Col className="mt-2" md={6} sm={6} xs={12}>
											<Formik
												initialValues={{ searchTasksQuery: "" }}
												validationSchema={Yup.object({
													searchTasksQuery: Yup.string(),
												})}
												onSubmit={async (values, { setSubmitting }) => {
													try {
														setSubmitting(true);
														setSearchTasksQuery(values.searchTasksQuery);
														setPage(1);
														if (companyId && projectId) {
															if (values.searchTasksQuery.trim() === "") {
																await dispatch(
																	getAllTasksAsync({
																		company_id: companyId,
																		projectId: projectId,
																		page: 1,
																	}),
																);
															} else {
																await dispatch(
																	searchTasksAsync({
																		company_id: companyId,
																		projectId: projectId,
																		searchTerm: values.searchTasksQuery,
																		page: 1,
																	}),
																).unwrap();
															}
														}
													} catch (error) {
														console.log(error);
													} finally {
														setSubmitting(false);
													}
												}}
											>
												{({ isSubmitting, handleSubmit }) => (
													<Form onSubmit={handleSubmit}>
														<div className="mx-2 border border-1 rounded py-1 px-2 d-inline-flex justify-content-between align-items-center my-3">
															<Field
																type="search"
																name="searchTasksQuery"
																placeholder="Search for a task"
																className="bg-transparent border-0"
																style={{ outline: "none" }}
																aria-label="Search for a task"
															/>
															<button
																type="submit"
																className={`pointer ${isSubmitting ? "disabled" : ""
																	}`}
																disabled={isSubmitting}
																style={{
																	background: "none",
																	border: "none",
																	boxShadow: "none",
																}}
																aria-label="search"
															>
																<img src={searchIcon} alt="search icon" />
															</button>
														</div>
													</Form>
												)}
											</Formik>
										</Col>
										<Col
											className="mt-2 d-inline-flex justify-content-end"
											md={6}
											sm={6}
											xs={12}
										>
											{hasPermission(permissions, "create-schedule") && (
												<Button
													onClick={() => handleOpenCreateModal("task")}
													className="py-3 px-5 border-0 bg-color-1"
												>
													<span className="text-center">Add schedule</span>
												</Button>
											)}
										</Col>
									</Row>
									<Table borderless responsive>
										<thead>
											<tr>
												<th className="text-color-1 bg-color-3">S/N</th>
												<th className="text-color-1 bg-color-3">Task name </th>
												<th className="text-color-1 bg-color-3">
													Assigned to{" "}
												</th>
												<th className="text-color-1 bg-color-3">
													Planned start date{" "}
												</th>
												<th className="text-color-1 bg-color-3">
													Planned end date{" "}
												</th>

												<th className="text-color-1 bg-color-3">Status</th>
												<th className="text-color-1 bg-color-3"></th>
											</tr>
										</thead>
										<tbody>
											{tasks?.map((task, index) => (
												<tr key={task.id}>
													<td style={{ verticalAlign: "middle" }}>
														{index + 1}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{task.task_name}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{task.assigned_to}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{format(new Date(task.start_date), "dd/MM/yy")}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{format(new Date(task.end_date), "dd/MM/yy")}
													</td>
													<td
														style={{
															color: getStatusColor(task.task_status),
															verticalAlign: "middle",
														}}
													>
														{task.task_status}
													</td>

													<td style={{ verticalAlign: "middle" }}>
														<Dropdown
															show={taskDropdownOpen[index]}
															onToggle={() => toggleTaskDropdown(index)}
														>
															<Dropdown.Toggle
																className="dropdown-toggle"
																variant="transparent"
																style={{
																	background: "none",
																	border: "none",
																	boxShadow: "none",
																}}
															>
																<img
																	src={action}
																	alt="Action"
																	className="action-icon"
																	style={{ margin: "0", padding: "0" }}
																/>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																{hasPermission(
																	permissions,
																	"edit-schedule",
																) && (
																		<Dropdown.Item
																			onClick={() => handleOpenEditTask(task.id)}
																		>
																			Edit
																		</Dropdown.Item>
																	)}
																{hasPermission(
																	permissions,
																	"delete-schedule",
																) && (
																		<Dropdown.Item
																			onClick={() => handleDeleteTask(task.id)}
																		>
																			Delete
																		</Dropdown.Item>
																	)}
															</Dropdown.Menu>
														</Dropdown>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
									{tasksMeta && tasksMeta.total_pages > 1 && (
										<div className="d-flex justify-content-center mt-3 pb-5">
											<Pagination
												count={tasksMeta && tasksMeta?.total_pages}
												page={tasksPage}
												onChange={handleTasksChange}
												shape="rounded"
												color="primary"
											/>
										</div>
									)}
								</div>
							)}
						</div>
						<div>
							{activeTab === "gantt-chart" && (
								<div className="table-container w-100 d-flex flex-column justify-content-center align-content-center ">
									<div className="my-3 align-self-end d-inline-flex w-25">
										<p className="py-2 px-1 mx-2">Change View: </p>
										<Dropdown>
											<Dropdown.Toggle
												className="bg-color-1 border-0"
												id="dropdown-basic"
											>
												{viewConfig.scale_unit === "day"
													? "Day View"
													: viewConfig.scale_unit === "week"
														? "Week View"
														: "Month View"}
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<Dropdown.Item
													onClick={() => setViewConfig(dayScaleConfig)}
												>
													Day View
												</Dropdown.Item>
												<Dropdown.Item
													onClick={() => setViewConfig(weekScaleConfig)}
												>
													Week View
												</Dropdown.Item>
												<Dropdown.Item
													onClick={() => setViewConfig(monthScaleConfig)}
												>
													Month View
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>

									<div
										className="gantt-chart-container"
										style={{
											width: "100%",
											height: "300px",
											overflow: "auto",
										}}
										ref={ganttContainer}
									></div>
								</div>
							)}
						</div>
						<div>
							{activeTab === "issues" &&
								(issues && issues.length > 0 ? (
									<div className="table-container bg-color-9 px-5 pt-3 mt-4">
										<Row>
											<Col md={12}>
												<Formik
													initialValues={{ searchIssuesQuery: "" }}
													validationSchema={Yup.object({
														searchIssuesQuery: Yup.string(),
													})}
													onSubmit={async (values, { setSubmitting }) => {
														try {
															setSubmitting(true);
															setSearchIssuesQuery(values.searchIssuesQuery);
															setPage(1);
															if (companyId && projectId) {
																if (values.searchIssuesQuery.trim() === "") {
																	await dispatch(
																		getAllIssuesAsync({
																			company_id: companyId,
																			projectId: projectId,
																			page: 1,
																		}),
																	);
																} else {
																	await dispatch(
																		searchIssuesAsync({
																			company_id: companyId,
																			projectId: projectId,
																			searchTerm: values.searchIssuesQuery,
																			page: 1,
																		}),
																	).unwrap();
																}
															}
														} catch (error) {
															console.log(error);
														} finally {
															setSubmitting(false);
														}
													}}
												>
													{({ isSubmitting, handleSubmit }) => (
														<Form onSubmit={handleSubmit}>
															<div className="mx-2 border border-1 rounded py-1 px-2 d-inline-flex justify-content-between align-items-center my-3">
																<Field
																	type="search"
																	name="searchIssuesQuery"
																	placeholder="Search for an issue"
																	className="bg-transparent border-0"
																	style={{ outline: "none" }}
																	aria-label="Search for an issue"
																/>
																<button
																	type="submit"
																	className={`pointer ${isSubmitting ? "disabled" : ""
																		}`}
																	disabled={isSubmitting}
																	style={{
																		background: "none",
																		border: "none",
																		boxShadow: "none",
																	}}
																	aria-label="search"
																>
																	<img src={searchIcon} alt="search icon" />
																</button>
															</div>
														</Form>
													)}
												</Formik>
											</Col>
											<Col md={12}>
												{hasPermission(permissions, "create-schedule") && (
													<Button
														onClick={() => handleOpenCreateModal("issue")}
														className="py-3 px-5 border-0 bg-color-1 float-end"
													>
														<span className="text-center">Add Issue</span>
													</Button>
												)}
											</Col>
										</Row>
										<Table borderless responsive>
											<thead>
												<tr>
													<th className="text-color-1 bg-color-3">S/N</th>
													<th className="text-color-1 bg-color-3">
														Issue name{" "}
													</th>
													<th className="text-color-1 bg-color-3">
														Associated task{" "}
													</th>
													<th className="text-color-1 bg-color-3">
														Assigned to{" "}
													</th>
													<th className="text-color-1 bg-color-3">Priority </th>
													<th className="text-color-1 bg-color-3">Status</th>
													<th className="text-color-1 bg-color-3">Image</th>
													<th className="text-color-1 bg-color-3">Action</th>
												</tr>
											</thead>
											<tbody>
												{issues?.map((issue, index) => (
													<tr key={issue.id}>
														<td style={{ verticalAlign: "middle" }}>
															{issues.indexOf(issue) + 1}
														</td>
														<td style={{ verticalAlign: "middle" }}>
															{issue.issue_name}
														</td>
														<td style={{ verticalAlign: "middle" }}>
															{issue.associated_task}
														</td>
														<td style={{ verticalAlign: "middle" }}>
															{issue.assigned_to}
														</td>
														<td style={{ verticalAlign: "middle" }}>
															{issue.priority}
														</td>
														<td
															style={{
																color: getStatusColor(issue.status),
																verticalAlign: "middle",
															}}
														>
															{issue.status}
														</td>
														<td style={{ verticalAlign: "middle" }}>
															{issue.image_url ? (
																<span
																	className="text-color-1"
																	style={{ cursor: "pointer" }}
																	onClick={() => handleViewImage(issue.image_url, issue.id, issue.issue_name)}
																>
																	View
																</span>
															) : (
																"No Image"
															)}
														</td>

														<td style={{ verticalAlign: "middle" }}>
															<Dropdown
																show={issueDropdownOpen[index]}
																onToggle={() => toggleIssueDropdown(index)}
															>
																<Dropdown.Toggle
																	className="dropdown-toggle"
																	variant="transparent"
																	style={{
																		background: "none",
																		border: "none",
																		boxShadow: "none",
																	}}
																>
																	<img
																		src={action}
																		alt="Action"
																		className="action-icon"
																		style={{ margin: "0", padding: "0" }}
																	/>
																</Dropdown.Toggle>
																<Dropdown.Menu>
																	{hasPermission(permissions, "edit-issue") && (
																		<Dropdown.Item
																			onClick={() =>
																				handleOpenEditIssue(issue.id)
																			}
																		>
																			Edit
																		</Dropdown.Item>
																	)}
																	{/* {hasPermission(
																		permissions,
																		"delete-issue",
																	) && (
																			<Dropdown.Item
																				onClick={() =>
																					handleDeleteIssue(issue.id)
																				}
																			>
																				Delete
																			</Dropdown.Item>
																		)} */}
																</Dropdown.Menu>
															</Dropdown>
														</td>
													</tr>
												))}
											</tbody>
										</Table>
										{issuesMeta && issuesMeta.total_pages > 1 && (
											<div className="d-flex justify-content-center mt-3 pb-5">
												<Pagination
													count={issuesMeta && issuesMeta?.total_pages}
													page={issuesPage}
													onChange={handleIssuesChange}
													shape="rounded"
													color="primary"
												/>
											</div>
										)}
									</div>
								) : (
									<NoIssue handleCreateSuccess={handleCreateSuccess} />
								))}
						</div>
					</>
				</div>
				{openModal.createTask === "task" && (
					<CreateTask
						closeModal={handleCloseCreateModal}
						onCreateSuccess={handleCreateSuccess}
						project_id={projectId}
						show={openModal.createTask !== null}
					/>
				)}

				{selectedTaskId && (
					<>
						<UpdateTask
							closeModal={handleCloseEditTask}
							id={selectedTaskId!}
							onEditSuccess={handleEditSuccess}
							project_id={projectId}
							show={editTaskOpen}
						/>
					</>
				)}
				{openModal.createIssue === "issue" && (
					<CreateIssue
						closeModal={handleCloseCreateModal}
						onCreateSuccess={handleCreateSuccess}
						project_id={projectId}
						show={openModal.createIssue !== null}
					/>
				)}
				{selectedIssueId && (
					<>
						<UpdateIssue
							closeModal={handleCloseEditIssue}
							id={selectedIssueId!}
							onEditSuccess={handleEditSuccess}
							project_id={projectId}
							show={editIssueOpen}
						/>
					</>
				)}
				<ViewImage
					show={showImageModal}
					closeModal={() => setShowImageModal(false)}
					selectedImage={selectedImage}
					issueName={selectedIssueName || "Issue"}
				/>
			</Layout>
		</div>
	);
}

export default ProjectManagement;
