import axiosInstance from '../axiosInstance';

export interface Budget {
    amount_spent: string;
    budget_amount: string;
    budget_type: string;
    budget_type_id: string;
    company_id: string;
    id: string;
    projectId: string;
}
export interface CreateBudgetData {
    budget_amount: string;
    budget_type_id: string;
    company_id: string;
    projectId: string;
}
export interface CreateBudgetResponse {
    data: Budget;
    message: string;
    error: boolean;
}
export interface UpdateBudgetData {
    budget_amount: string;
    id: string;
    company_id: string;
    projectId: string;
}
export interface UpdateBudgetResponse {
    data: Budget;
    message: string;
    error: boolean;
}
export interface GetAllBudgetsResponse {
    data: Budget[];
    message: string;
}
export interface GetABudgetResponse {
    data: Budget;
    message: string;
}

export const createBudget = async (values: CreateBudgetData): Promise<CreateBudgetResponse> => {
    const response = await axiosInstance.post<CreateBudgetResponse>(`/projects/${values.projectId}/budgets/create`, values);
    return response.data;
}
export const updateBudget = async (
    values: UpdateBudgetData,
    id: string,
): Promise<UpdateBudgetResponse> => {
    const response = await axiosInstance.put<UpdateBudgetResponse>(`/projects/${values.projectId}/budgets/${id}/update`, values);
    return response.data;
}
export const getAllBudgets = async (companyId: string, projectId: string): Promise<Budget[]> => {
    const response = await axiosInstance.get<GetAllBudgetsResponse>(`/projects/${projectId}/budgets/?company_id=${companyId}`);
    return response.data.data;
}
export const getABudget = async (companyId: string, id: string, projectId: string): Promise<Budget> => {
    const response = await axiosInstance.get<GetABudgetResponse>(`/projects/${projectId}/budgets/${id}?company_id=${companyId}`);
    return response.data.data;
}