import React, { useEffect, useState } from 'react'
import SettingsLayout from '../settings-layout'
import CloseButton from 'components/close-button/close-button'
import { Col, Container, Nav } from 'react-bootstrap'
import { PaystackButton } from 'react-paystack';
import { useAppSelector } from "../../../store/hooks";

function Billings() {
	const [activeTab, setActiveTab] = useState<string>("monthly");
	const [amount, setAmount] = useState(50000)
	const [email, setEmail] = useState('')
	const handleTabSelect = (key: string | null) => {
		if (key) {
			setActiveTab(key);
		}
		if (activeTab === 'quarterly') {
			setAmount(200000)
		} else if (activeTab === 'yearly') {
			setAmount(50000)
		}
	};



	const { user } = useAppSelector(state => state.auth);

	useEffect(() => {
		if (user !== null && user !== undefined) {
			setEmail(user.email);
		}
	}, []);

	const handlePaystackSuccessAction = (reference: any) => {
		console.log('ref', reference);
	};

	const handlePaystackCloseAction = () => {
		console.log('closed')
	  }
	const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || ""
	const componentProps = {
		email: email,
		amount: amount * 100,
		publicKey: publicKey,
		text: 'Get Started',
		onSuccess: (reference: any) => handlePaystackSuccessAction(reference),
		onClose: handlePaystackCloseAction,
	}

	
	return (
		<div className="bg-color-8">
			<SettingsLayout>

				<div className="pt-5 p-5">
					<div className="w-100 mb-5 mt-5 d-inline-flex justify-content-between align-items-center">
						<h4 className="text-color-2">Choose Plan</h4>
						<CloseButton />
					</div>

					<div className="bg-color-9 px-4 pt-3 mt-4 pb-5 vh-50">
						<Container
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								marginRight: 'auto',
								marginLeft: 'auto',
								width: '95%'
							}}
						>
							<div>
								<h1 style={{ fontSize: "16px", }}>You are currently on the <span style={{ color: "#0F6CBD", fontWeight: "bold" }}>Free trial</span> plan</h1>
							</div>

							<div>

								<Nav
									activeKey={activeTab}
									onSelect={handleTabSelect}
									className="nav px-2 py-2"
								>
									<Nav.Item>
										<Nav.Link
											className=" px-1 py-0 rounded fw-light mx-2"
											eventKey="monthly"
											style={{
												textDecoration: "none",
												color: activeTab === "monthly" ? " #FFFFFF " : " #747487",
												fontSize: "14px",
												background:
													activeTab === "monthly" ? "#0F6CBD" : "transparent",
											}}
										>
											Monthly
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											className=" px-1 py-0 rounded fw-light mx-2"
											eventKey="quarterly"
											style={{
												color: activeTab === "quarterly" ? " #FFFFFF " : " #747487",
												textDecoration: "none",
												fontSize: "14px",
												background:
													activeTab === "quarterly" ? "#0F6CBD" : "transparent",
											}}
										>
											Quarterly
										</Nav.Link>
									</Nav.Item>

									<Nav.Item>
										<Nav.Link
											className=" px-1 py-0 rounded fw-light mx-2"
											eventKey="yearly"
											style={{
												color: activeTab === "yearly" ? " #FFFFFF " : " #747487",
												textDecoration: "none",
												fontSize: "14px",
												background:
													activeTab === "yearly" ? "#0F6CBD" : "transparent",
											}}
										>
											Yearly
										</Nav.Link>
									</Nav.Item>
								</Nav>

							</div>
						</Container>


						<Container style={{
							display: 'flex',
							flexWrap: 'wrap',
							gap: 10,
							justifyContent: 'space-between',
							alignItems: 'center',
							marginRight: 'auto',
							marginLeft: 'auto',
							width: '95%',
							marginBottom: '20px ',
							marginTop: '60px'
						}}>
							<Col md={4} style={{ height: '180px', width: '232px', border: '1px solid #0F6CBD66', borderRadius: '10px', padding: '10px' }}>
								<h2 style={{ fontSize: '14px', marginBottom: '16px', marginTop: '20px' }}>Free trial</h2>
								<h2 style={{ fontSize: '18px', marginBottom: '10px' }}>30 days period</h2>
								<div className='d-flex justify-content-center mt-4'>
									<button className='px-4 py-3 border-0 rounded-2 bg-color-11 w-100 text-white  fs-6 fs-md-3'>Your current plan</button>
								</div>
							</Col>

							<Col md={4} style={{ height: '180px', width: '232px', border: '1px solid #0F6CBD66', borderRadius: '10px', padding: '10px' }}>
								<h2 style={{ fontSize: '14px', marginBottom: '16px', marginTop: '20px' }}>Standard</h2>
								<h2 style={{ fontSize: '18px', marginBottom: '10px' }}>{activeTab === 'monthly' ? 'NGN 50,000/ Month' : activeTab === 'quarterly' ? 'NGN 200,000/ Quater' : 'NGN 50,000/ Year'}</h2>
								<div className='d-flex justify-content-center mt-4'>
									<PaystackButton {...componentProps} className='px-4 py-3 border-0  rounded-2 bg-color-12 w-100 text-white fs-6 fs-md-3' />
								</div>
							</Col>

							<Col md={4} style={{ height: '180px', width: '232px', border: '1px solid #0F6CBD66', borderRadius: '10px', padding: '10px' }}>
								<h2 style={{ fontSize: '14px', marginBottom: '16px', marginTop: '20px' }}>Enterprise</h2>
								<h2 style={{ fontSize: '18px', marginBottom: '10px', }}>Custom pricing</h2>
								<div className='d-flex justify-content-center mt-4'>
									<button className='px-4 py-3 border-0  rounded-2 bg-color-12 w-100 text-white fs-6 fs-md-3'>Contact sales</button>
								</div>
							</Col>

						</Container>
					</div>
				</div>
			</SettingsLayout>
		</div>
	)
}

export default Billings