import React, { useState, useEffect } from "react";
import SettingsLayout from "../settings-layout";
import Action from "../../../assets/images/action-vector.png";
import { Form, Button, Table } from "react-bootstrap";
import SetupApprovalLevel from "./modals/setup-approval-level";
import CloseButton from "../../../components/close-button/close-button";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { getCompanyProfileAsync } from "slices/settings/settingsSlice";
import UserNotPaid from "./modals/user-not-paid";
import DefaultApprover from "./modals/default-approval";
import { getAllDropdownProjectsAsync } from "slices/project/projectSlice";

function ApprovalLevel() {
	const [setupApprovalLevelOpen, setSetupApprovalLevelOpen] =
		useState<boolean>(false);
	const [userNotPaidOpen, setUserNotPaidOpen] = useState<boolean>(false);
	const [selectedOption, setSelectedOption] = useState<string>("default"); // Track selected option

	const dispatch = useAppDispatch();
	const handleOpenSetupApprovalLevel = () => setSetupApprovalLevelOpen(true);
	const handleCloseSetupApprovalLevel = () => setSetupApprovalLevelOpen(false);
	const handleOpenUserNotPaid = () => setUserNotPaidOpen(true);
	const handleCloseUserNotPaid = () => setUserNotPaidOpen(false);

	const [defaultApproverModalOpen, setDefaultApproverModalOpen] =
		useState<boolean>(false);
	const handleOpenDefaultApproverModal = () =>
		setDefaultApproverModalOpen(true);
	const handleCloseDefaultApproverModal = () =>
		setDefaultApproverModalOpen(false);

	const projects = useAppSelector((state) => state.project.projects?.data) || [];
	const approvers = useAppSelector(
		(state: RootState) => state.approver.approvers,
	);
	const customapprovers = useAppSelector(
		(state: RootState) => state.approver.customapprovers,
	);
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || 0,
	);
	const is_paid = useAppSelector(
		(state: RootState) => state.settings.companyProfile?.is_paid,
	);
	const companyApprover = useAppSelector(
		(state: any) => state.settings.companyProfile?.company_approver || "",
	);

	useEffect(() => {
		if (companyId) {
			dispatch(getCompanyProfileAsync(companyId));
		}
	}, [dispatch, companyId]);
	useEffect(() => {
		if (companyId) {
			dispatch(getAllDropdownProjectsAsync({ companyId }));
		}
	}, [dispatch, companyId]);

	console.log("DropdownProjects:", projects);

	const handleCreateSuccess = () => {
		if (companyId) {
			dispatch(getAllDropdownProjectsAsync({ companyId }));
		}
	};
	const handleUpdateSuccess = () => {
		if (companyId) {
			dispatch(getCompanyProfileAsync(companyId));
		}
	};
	return (
		<div className="">
			<SettingsLayout>
				<div className="pt-5 p-5">
					<div className="w-100 d-inline-flex justify-content-between align-items-center">
						<h4 className="mb-5">Approval level</h4>
						<CloseButton />
					</div>
					<div>
						<h6 className="mb-3 text-color-2">Choose your preferred option</h6>
						<div className="mb-3 d-flex flex-column">
							<Form.Check
								inline
								label="Default"
								name="group1"
								type="radio"
								checked={selectedOption === "default"}
								onChange={() => setSelectedOption("default")}
							/>
							<p className="dash-texts-7">
								This option allows you to select only one approver
							</p>
							<span className="mb-2 dash-texts-7">
								Approver:{" "}
								<span className="text-color-8">
									{companyApprover || "No one selected"}
								</span>
							</span>
							<Button
								className={`bg-color-1 text-light border-0 w-20 w-40-m w-100-s py-3 px-4 mb-3 ${selectedOption === "custom" ? "faded" : ""
									}`}
								onClick={handleOpenDefaultApproverModal}
								disabled={selectedOption === "custom"} // Disable if "Custom" is selected
							>
								Change approver
							</Button>

							<Form.Check
								inline
								label="Custom"
								name="group1"
								type="radio"
								checked={selectedOption === "custom"}
								onChange={() => setSelectedOption("custom")}
							/>
							<p style={{ fontSize: "0.65em" }}>
								This option allows you to select multi-level approval level
							</p>
							<Button
								onClick={
									is_paid ? handleOpenSetupApprovalLevel : handleOpenUserNotPaid
								}
								className={`bg-color-1 text-light border-0 w-20 w-40-m w-100-s py-3 px-4 mb-3 ${selectedOption === "default" ? "faded" : ""
									}`}
								disabled={selectedOption === "default"} // Disable if "Default" is selected
							>
								Setup approval
							</Button>
						</div>
						<div className="w-50 w-100-m w-100-s">
							<Table borderless responsive>
								<thead>
									<tr>
										<th className="text-color-1 bg-color-3">S/N</th>
										<th className="text-color-1 bg-color-3">Project name</th>
										<th className="text-color-1 bg-color-3">Number of level</th>
										<th className="text-color-1 bg-color-3"></th>
									</tr>
								</thead>
								<tbody>
									{projects.map((project, index) => (
										<tr key={project.id}>
											<td>{index + 1}</td>
											<td>{project.project_name}</td>
											<td>{project.approval_levels}</td>
											<td style={{ padding: "0", verticalAlign: "middle" }}>
												<img
													src={Action}
													alt="Action"
													className="action-icon mb-3"
													style={{ margin: "0", padding: "0" }}
												/>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
					</div>
				</div>

				<DefaultApprover
					show={defaultApproverModalOpen}
					closeModal={handleCloseDefaultApproverModal}
					onCreateSuccess={handleUpdateSuccess}
				/>
				<SetupApprovalLevel
					show={setupApprovalLevelOpen}
					closeModal={handleCloseSetupApprovalLevel}
					approver={approvers}
					customapprover={customapprovers}
					onCreateSuccess={handleCreateSuccess}
				/>
				<UserNotPaid
					show={userNotPaidOpen}
					closeModal={handleCloseUserNotPaid}
				/>
			</SettingsLayout>
		</div>
	);
}

export default ApprovalLevel;
